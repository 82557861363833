import React from 'react';
import '../assets/ContactPage.css';

const ContactPage = () => {
  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <p>
        Have questions about the <strong>Intelligent Cloud-Based Network Performance Analyzer (ICNPA)</strong>? 
        Whether you're curious about our product offerings, need technical support, or have a business inquiry, 
        we're here to help. Get in touch with us by using the contact information below.
      </p>
      <h2>Product Inquiries</h2>
      <p>
        Are you interested in learning more about <strong>ICNPA's features and capabilities</strong>? Reach out to 
        our sales team for:
      </p>
      <ul>
        <li>Details on <strong>ICNPA's monitoring and analytics capabilities</strong></li>
        <li><strong>Customization options</strong> for enterprise networks</li>
        <li>Licensing and subscription plans</li>
        <li>Partnership opportunities</li>
      </ul>

      <h2>Technical Support</h2>
      <p>
        Experiencing technical issues or need assistance with installation and configuration? Contact our 
        support team for:
      </p>
      <ul>
        <li><strong>Troubleshooting connectivity and performance issues</strong></li>
        <li>Guidance on <strong>ICNPA integration with existing systems</strong></li>
        <li>Documentation and user guides</li>
        <li>Bug reporting and feature requests</li>
      </ul>

      <h2>Business and General Inquiries</h2>
      <p>
        For <strong>partnerships, collaborations, or general questions</strong>, please email us or use the 
        form below. We look forward to connecting with you.
      </p>
      <h3>Contact Information</h3>
      <p>
        <strong>Email:</strong> support@icnpa.com<br />
        <strong>Phone:</strong> +1-800-123-ICNPA (42672)<br />
        <strong>Address:</strong> 123 ICNPA Lane, Tech City, WV, USA
      </p>
      <h2>Contact Form</h2>
      <p>
        Alternatively, fill out the contact form below, and we’ll get back to you as soon as possible:
      </p>
      <form>
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" placeholder="Enter your name" />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" placeholder="Enter your email" />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" placeholder="Type your message here"></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContactPage;
