import React, { useState, useEffect } from 'react';
import api from '../api'; 
import '../assets/HomePage.css';

const HomePage = () => {
  const [metrics, setMetrics] = useState({
    activeConnections: null,
    bandwidthUtilization: null,
    dnsResolutionTime: null,
    networkLatency: null,
    networkAvailability: null,
    topApplications: null,
    cpuUsage: null,
    ramUsage: null,
    diskUsage: null,
    diskIo: null,
    networkTraffic: null,
    loadAverage: null,
  });

  const [loading, setLoading] = useState(false);

  const fetchMetrics = async () => {
    try {
      setLoading(true); 
      const [
        activeConnections, bandwidthUtilization, dnsResolutionTime, networkLatency, networkAvailability,
        topApplications, cpuUsage, ramUsage, diskUsage, diskIo, networkTraffic, loadAverage
      ] = await Promise.all([
        api.getMetric('active-connections'),
        api.getMetric('bandwidth-utilization'),
        api.getMetric('dns-resolution-time'),
        api.getMetric('network-latency'),
        api.getMetric('network-availability'),
        api.getMetric('top-applications'),
        api.getMetric('cpu-usage'),
        api.getMetric('ram-usage'),
        api.getMetric('disk-usage'),
        api.getMetric('disk-io'),
        api.getMetric('network-traffic'),
        api.getMetric('load-average'),
      ]);
  
      console.log('Fetched metrics:', {
        activeConnections, bandwidthUtilization, dnsResolutionTime, networkLatency, networkAvailability,
        topApplications, cpuUsage, ramUsage, diskUsage, diskIo, networkTraffic, loadAverage
      });

      setMetrics({
        activeConnections,
        bandwidthUtilization,
        dnsResolutionTime,
        networkLatency,
        networkAvailability,
        topApplications,
        cpuUsage,
        ramUsage,
        diskUsage,
        diskIo,
        networkTraffic,
        loadAverage
      });
    } catch (error) {
      console.error('Error fetching metrics:', error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchMetrics();  

    const interval = setInterval(() => {
      fetchMetrics();
    }, 150000); 
    return () => clearInterval(interval);
  }, []);  

  const renderValues = (values) => {
    if (!values) return 'Not Available';
    
    if (Array.isArray(values)) {
      return values.map((item, index) => (
        <div key={index}>{item}</div>
      ));
    }
    
    if (typeof values === 'string' && values.includes(',')) {
      return values.split(',').map((item, index) => (
        <div key={index}>{item.trim()}</div>
      ));
    }

    return values;
  };

  return (
    <div className="homepage-container" style={{ paddingBottom: '10%' }}>
      <h1>Welcome to ICNPA!</h1>
      <p>This is the homepage for the Intelligent Cloud-Based Network Performance Analyzer.</p>
      
      {/* Show Loading if fetching data */}
      {loading && (
        <div className="loading-overlay">
          <h2>Update in Progress...</h2>
        </div>
      )}

      {/* Network Metrics Section */}
      <div className="metrics-container">
        <div className="section-title">
          <h2>Network Metrics</h2>
        </div>
        <div className="metrics-box">
          <div className="metric-card">
            <h3>Network Latency</h3>
            <p>{metrics.networkLatency || 'Not Available'}</p>
          </div>
          <div className="metric-card">
            <h3>Bandwidth Utilization</h3>
            <p>{metrics.bandwidthUtilization || 'Not Available'}</p>
          </div>
          <div className="metric-card">
            <h3>Active Connections</h3>
            <p>{metrics.activeConnections || 'Not Available'}</p>
          </div>
          <div className="metric-card">
            <h3>Network Availability</h3>
            <p>{metrics.networkAvailability || 'Not Available'}</p>
          </div>
          <div className="metric-card">
            <h3>Top Applications</h3>
            <p>{renderValues(metrics.topApplications)}</p>
          </div>
          <div className="metric-card">
            <h3>DNS Resolution Time</h3>
            <p>{metrics.dnsResolutionTime || 'Not Available'}</p>
          </div>
        </div>
      </div>

      {/* Server Metrics Section */}
      <div className="metrics-container">
        <div className="section-title">
          <h2>Server Metrics</h2>
        </div>
        <div className="metrics-box">
          <div className="metric-card">
            <h3>CPU Usage</h3>
            <p>{metrics.cpuUsage || 'Not Available'}</p>
          </div>
          <div className="metric-card">
            <h3>RAM Usage</h3>
            <p>{renderValues(metrics.ramUsage)}</p>
          </div>
          <div className="metric-card">
            <h3>Disk Usage</h3>
            <p>{renderValues(metrics.diskUsage)}</p>
          </div>
          <div className="metric-card">
            <h3>Disk I/O</h3>
            <p>{renderValues(metrics.diskIo)}</p>
          </div>
          <div className="metric-card">
            <h3>Load Average</h3>
            <p>{metrics.loadAverage || 'Not Available'}</p>
          </div>
          <div className="metric-card">
            <h3>Network Traffic</h3>
            <p>{renderValues(metrics.networkTraffic)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;