import React from 'react';
import '../assets/AboutPage.css';

const AboutPage = () => {
  return (
    <div className="about-container">
      <h1>About ICNPA</h1>
      <p>
        Welcome to the <strong>Intelligent Cloud-Based Network Performance Analyzer (ICNPA)</strong>. 
        ICNPA is an innovative tool designed to monitor, analyze, and enhance network performance 
        across various environments. This platform combines cutting-edge technology with user-friendly 
        design, making it accessible to both novice users and advanced network administrators.
      </p>
      <p>
        The primary goal of ICNPA is to empower users by providing:
      </p>
      <ul className="about-list">
        <li><strong>Comprehensive Network Monitoring:</strong> Track critical metrics such as latency, bandwidth utilization, and throughput in real time.</li>
        <li><strong>Actionable Insights:</strong> Leverage data visualization and analysis to identify performance bottlenecks and optimize resource allocation.</li>
        <li><strong>Scalability:</strong> Whether you’re managing a small home lab or an enterprise network, ICNPA adapts to your needs.</li>
        <li><strong>Cloud-Based Flexibility:</strong> Monitor your network anytime, anywhere, with secure access through cloud technology.</li>
      </ul>
      <p>
        ICNPA was developed as part of a capstone project, emphasizing robust functionality, modular 
        design, and open-source collaboration. We aim to continually improve and expand the platform 
        to meet the evolving demands of network performance management.
      </p>
    </div>
  );
};

export default AboutPage;
