import axios from 'axios';

const BASE_URL = 'http://localhost:5000/api';  

const api = {
  getMetrics: async () => {
    try {
      const response = await Promise.all([
        axios.get(`${BASE_URL}/metrics/network-latency`),
        axios.get(`${BASE_URL}/metrics/packet-loss`),
        axios.get(`${BASE_URL}/metrics/bandwidth-utilization`),
        axios.get(`${BASE_URL}/metrics/active-connections`),
        axios.get(`${BASE_URL}/metrics/top-protocol`),
        axios.get(`${BASE_URL}/metrics/jitter`),
        axios.get(`${BASE_URL}/metrics/top-traffic-sources`),
        axios.get(`${BASE_URL}/metrics/dns-resolution-time`),
        axios.get(`${BASE_URL}/metrics/error-rate`),
        axios.get(`${BASE_URL}/metrics/total-data-transferred`),
        axios.get(`${BASE_URL}/metrics/network-availability`),
        axios.get(`${BASE_URL}/metrics/top-applications`),
        axios.get(`${BASE_URL}/metrics/cpu-usage`),          
        axios.get(`${BASE_URL}/metrics/ram-usage`),          
        axios.get(`${BASE_URL}/metrics/disk-usage`),         
        axios.get(`${BASE_URL}/metrics/disk-io`),           
        axios.get(`${BASE_URL}/metrics/load-average`),      
        axios.get(`${BASE_URL}/metrics/network-traffic`),    
      ]);

      console.log('API Response:', response);  // Log full API response for debugging

      return {
        networkLatency: response[0].data.value,
        packetLoss: response[1].data.value,
        bandwidthUtilization: response[2].data.value,
        activeConnections: response[3].data.value,
        topProtocol: response[4].data.value,
        jitter: response[5].data.value,
        topTrafficSources: response[6].data.value,
        dnsResolutionTime: response[7].data.value,
        errorRate: response[8].data.value,
        totalDataTransferred: response[9].data.value,
        networkAvailability: response[10].data.value,
        topApplications: response[11].data.value,
        cpuUsage: response[12].data.value,                 
        ramUsage: response[13].data.value,               
        diskUsage: response[14].data.value,              
        diskIo: response[15].data.value,                  
        loadAverage: response[16].data.value,            
        networkTraffic: response[17].data.value,           
      };
    } catch (error) {
      console.error('Error fetching all metrics:', error);
      throw error;
    }
  },

  getMetric: async (metricName) => {
    try {
      const response = await axios.get(`${BASE_URL}/metrics/${metricName}`);
      console.log(`Fetched ${metricName}:`, response.data); // Log the fetched data for debugging
      return response.data.value; // Ensure we are returning the 'value' field
    } catch (error) {
      console.error(`Error fetching ${metricName}:`, error);
      throw error;
    }
  }
};

export default api;
